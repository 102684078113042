import Simple from '@/components/task-templates/Simple'
import CodeTest from '@/components/task-templates/CodeTest'
import ArrayIntro from './ArrayIntro'

export default [
  {
    component: ArrayIntro,
    label: '!'
  },
  {
    component: Simple,
    name: 'Массив нулей',
    text: 'Дано целое число \\(n\\). Создайте и выведите целочисленный массив из \\(n\\) элементов, заполненный нулями',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 0, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Метод fill',
    text: 'Дано целое число \\(n\\). Создайте и выведите целочисленный массив из \\(n\\) элементов, заполненный нулями, с помощью метода fill',
    note: 'Поскольку на обёртке нельзя использовать методы, в этой задаче, и во всех остальных, где указано использовать методы, решаем без обёртки',
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 0, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первый и последний элемент',
    text: 'Дан целочисленный массив \\(n\\). Выведите его первый и последний элементы через пробел',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Первый и последний элементы',
    examples: [
      {
        input: 'A([1])',
        output: '1 1'
      },
      {
        input: 'A([2, 3])',
        output: '2 3'
      },
      {
        input: 'A([4, 5, 6, 7])',
        output: '4 7'
      }
    ]
  },
  {
    component: Simple,
    name: '0..n-1',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) целых чисел, заполненный числами от \\(0\\) до \\(n\\) (\\(n\\) не включительно)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: '1..n',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) целых чисел, заполненный числами от \\(1\\) до \\(n\\) (включительно)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n раз n',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) целых чисел, заполненный числами \\(n\\)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 3, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n раз n через fill',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) целых чисел, заполненный числами \\(n\\) с помощью метода fill',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 3, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n..1',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) целых чисел, заполненный числами от \\(n\\) до \\(1\\) (включительно)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n-1..0',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) целых чисел, заполненный числами от \\(n\\) (не включительно) до \\(0\\) (включительно)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[2, 1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение на месте',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, умножив каждое число в нём на 2. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([1])',
        output: '[2]'
      },
      {
        input: 'A([-2, 0, 5])',
        output: '[-4, 0, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение на месте через одного со второго',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, умножив каждое второе число в нём на 2. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([1, 1, 1])',
        output: '[1, 2, 1]'
      },
      {
        input: 'A([1, 2, 2, 1])',
        output: '[1, 4, 2, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение на месте через одного с первого',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, умножив каждое второе число в нём, начиная с первого, на 2. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([1, 1, 1])',
        output: '[2, 1, 2]'
      },
      {
        input: 'A([1, 2, 2, 1])',
        output: '[2, 2, 4, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение нечётных на месте',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, умножив каждое нечётное число в нём на 2. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([1, 1, 1])',
        output: '[2, 2, 2]'
      },
      {
        input: 'A([1, 2, 3, 3])',
        output: '[2, 2, 6, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение нечётных, деление чётных',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, умножив каждое нечётное число в нём на 2, а каждое чётное разделив на 2. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([1, 1, 2])',
        output: '[2, 2, 1]'
      },
      {
        input: 'A([2, 4, 3, 5])',
        output: '[1, 2, 6, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обнуление отрицательных',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, заменив каждое отрицательное на 0. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([-1, 1, 2])',
        output: '[0, 1, 2]'
      },
      {
        input: 'A([-1, 2, 3, -4])',
        output: '[0, 2, 3, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Модуль по массиву',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, заменив каждое отрицательное на противоположное положительное. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[]'
      },
      {
        input: 'A([-1, 1, 2])',
        output: '[1, 1, 2]'
      },
      {
        input: 'A([-1, 2, 3, -4])',
        output: '[1, 2, 3, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод через одного',
    text: 'Дан целочисленный массив \\(a\\). Выведите каждое второе число из этого массива',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: 'A([1, 2, 4, 3])',
        output: '2\n3'
      },
      {
        input: 'A([2, 3, 4])',
        output: '3'
      },
      {
        input: 'A([1, 2, 3, 4, 5, 6])',
        output: '2\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод чётных',
    text: 'Дан целочисленный массив \\(a\\). Выведите каждое чётное число из этого массива',
    note: 'Здесь лучше использовать цикл for-of',
    input: '\\(a\\) - массив из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: 'A([1, 2, 4, 3])',
        output: '2\n4'
      },
      {
        input: 'A([2, 3, 4])',
        output: '2\n4'
      },
      {
        input: 'A([1, 2, 3, 4, 5, 6])',
        output: '2\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод *4 и *7',
    text: 'Дан целочисленный массив \\(a\\). Выведите каждое число из этого массива, которое заканчивается на 4 или 7',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: 'A([41, 76, 0])',
        output: ''
      },
      {
        input: 'A([2, 3, 4])',
        output: '4'
      },
      {
        input: 'A([4, 24, 107])',
        output: '4\n24\n107'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод по делимости',
    text: 'Дан целочисленный массив \\(a\\). Выведите каждое число из этого массива, которое не делится на 2 и 3',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: 'A([10, 9, 8])',
        output: ''
      },
      {
        input: 'A([2, 3, 4, 5, 6])',
        output: '5'
      },
      {
        input: 'A([1, 5, 7, 9])',
        output: '1\n5\n7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Поиск элемента',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). Выведите, входит ли \\(x\\) в \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: 'A([]), 1',
        output: 'false'
      },
      {
        input: 'A([10, 9, 9]), 9',
        output: 'true'
      },
      {
        input: 'A([2, 3, 4, 5, 6]), 7',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Поиск элемента через includes',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). Выведите, входит ли \\(x\\) в \\(a\\), используя метод includes',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: '[], 1',
        output: 'false'
      },
      {
        input: '[10, 9, 9], 9',
        output: 'true'
      },
      {
        input: '[2, 3, 4, 5, 6], 7',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс первого вхождения',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). Выведите индекс первого вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: 'A([]), 1',
        output: '-1'
      },
      {
        input: 'A([10, 9, 9]), 9',
        output: '1'
      },
      {
        input: 'A([7, 3, 4, 5, 6]), 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс первого вхождения через indexOf',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). С помощью метода indexOf выведите индекс первого вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '1'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс последнего вхождения',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). Выведите, индекс последнего вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: 'A([]), 1',
        output: '-1'
      },
      {
        input: 'A([10, 9, 9]), 9',
        output: '2'
      },
      {
        input: 'A([7, 3, 4, 5, 6]), 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс последнего вхождения через lastIndexOf',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). С помощью метода lastIndexOf выведите индекс последнего вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '2'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные числа от 0',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) чисел, заполненный чётными числами от 0 по порядку (0, 2, 4...)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 2, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётные числа',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) чисел, заполненный нечётными числами от 1 по порядку (1, 3, 5...)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные числа от 2',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив из \\(n\\) чисел, заполненный чётными числами от 2 по порядку (2, 4, 6...)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[2]'
      },
      {
        input: '3',
        output: '[2, 4, 6]'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'java',
    name: 'Массив - ссылочный тип',
    text: 'Даны два целочисленных массива \\(a\\) и \\(b\\). В массиве \\(a\\) замените первый элемент на последний элемент из \\(b\\). Затем перезапишите переменную \\(b\\), записав в неё \\(a\\). Выведите \\(b\\)',
    note: 'Посмотрите внимательно на код, и убедитесь, что понятно, почему результат именно такой',
    examples: [
      {
        input: `
          const a = A([5, 6, 7])
          const b = A([5, 6, 7])
          task30(a, b)
          console.log(a, b)
          const c = b
          task30(b, c)
          console.log(b, c)
        `,
        output: `
          [7, 6, 7]
          [7, 6, 7] [5, 6, 7]
          [7, 6, 7]
          [7, 6, 7] [7, 6, 7]
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение без мутации',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый массив такой же длины. Заполните его числами из \\(a\\), умноженными на 2. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[2]'
      },
      {
        input: 'A([1, 2])',
        output: '[2, 4]'
      },
      {
        input: 'A([5, 10, 5])',
        output: '[10, 20, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение через одного без мутации',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый массив такой же длины. Заполните его числами из \\(a\\), только каждое второе число умножьте на 2. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[1]'
      },
      {
        input: 'A([1, 2, 3])',
        output: '[1, 4, 3]'
      },
      {
        input: 'A([1, 1, 1, 1])',
        output: '[1, 2, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение нечётных без мутации',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый массив такой же длины. Заполните его числами из \\(a\\), только каждое нечётное число умножьте на 2. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[2]'
      },
      {
        input: 'A([1, 3, 2])',
        output: '[2, 6, 2]'
      },
      {
        input: 'A([1, 1, 1, 1])',
        output: '[2, 2, 2, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма по массивам',
    text: 'Дано два целочисленных массива \\(a\\) и \\(b\\) одинаковой длины. Создайте новый целочисленный массив той же длины. Заполните его суммами соответствующих элементов из \\(a\\) и \\(b\\) (первый с первым, второй со вторым и т. д.). Выведите новый массив',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2]), A([2, 3])',
        output: '[3, 5]'
      },
      {
        input: 'A([1, 2, 3]), A([3, 2, 1])',
        output: '[4, 4, 4]'
      },
      {
        input: 'A([1, 1, 1]), A([3, 2, 1])',
        output: '[4, 3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимумы по массивам',
    text: 'Дано два целочисленных массива \\(a\\) и \\(b\\) одинаковой длины. Создайте новый целочисленный массив той же длины. Заполните его максимальными числами из соответствующих элементов \\(a\\) и \\(b\\). Выведите новый массив',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2]), A([2, 3])',
        output: '[2, 3]'
      },
      {
        input: 'A([1, 2, 3]), A([3, 2, 1])',
        output: '[3, 2, 3]'
      },
      {
        input: 'A([1, 1, 1]), A([3, 2, 1])',
        output: '[3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Модули разности по массивам',
    text: 'Дано два целочисленных массива \\(a\\) и \\(b\\) одинаковой длины. Создайте новый целочисленный массив той же длины. Заполните его неотрицательными разницами чисел из соответствующих элементов \\(a\\) и \\(b\\). Выведите новый массив',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2]), A([2, 3])',
        output: '[1, 1]'
      },
      {
        input: 'A([1, 2, 3]), A([3, 2, 1])',
        output: '[2, 0, 2]'
      },
      {
        input: 'A([1, 1, 1]), A([3, 2, 1])',
        output: '[2, 1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Массив true/false по условию',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый булевый массив той же длины. Перебирая значения \\(a\\) по порядку, записывайте в него true, если встретилось положительное число, и false в противном случае. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[true]'
      },
      {
        input: 'A([1, -2, 0])',
        output: '[true, false, false]'
      },
      {
        input: 'A([0, 1, 2])',
        output: '[false, true, true]'
      }
    ]
  },
  {
    component: Simple,
    name: 'x..y',
    text: 'Даны целые числа \\(x\\) и \\(y\\) (\\(x < y\\)). Создайте и выведите массив, заполненный числами от \\(x\\) до \\(y\\) (включительно)',
    note: 'Длину массива нужно вычислить при создании',
    input: '\\(x\\) и \\(y\\) - целые',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0, 1',
        output: '[0, 1]'
      },
      {
        input: '5, 7',
        output: '[5, 6, 7]'
      },
      {
        input: '-2, 0',
        output: '[-2, -1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'y-1..x+1',
    text: 'Даны целые числа \\(x\\) и \\(y\\) (\\(x < y\\)). Создайте и выведите массив, заполненный числами от \\(y\\) (не включительно) до \\(x\\) (не включительно)',
    note: null,
    input: 'Заданы целые \\(x\\) и \\(y\\). (\\(-100 \\le x < y \\le 100\\))',
    output: 'Получившийся массив',
    examples: [
      {
        input: '0, 1',
        output: '[]'
      },
      {
        input: '5, 7',
        output: '[6]'
      },
      {
        input: '-2, 2',
        output: '[1, 0, -1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'По два раза',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив, заполненный числами от \\(1\\) до \\(n\\) (включительно), каждое число по два раза',
    note: 'if не использовать',
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '1',
        output: '[1, 1]'
      },
      {
        input: '2',
        output: '[1, 1, 2, 2]'
      },
      {
        input: '3',
        output: '[1, 1, 2, 2, 3, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Растущая прогрессия',
    text: 'Дано целое число \\(n\\). Создайте и выведите массив длины \\(n\\), в котором первое число 1, а каждое следующее больше на свой порядковый номер (\\(1, 1+2, 1+2+3, ...\\))',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 3, 6]'
      },
      {
        input: '5',
        output: '[1, 3, 6, 10, 15]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимальная сумма соседей',
    text: 'Дан целочисленный массив \\(a\\), содержащий минимум 2 элемента. Выведите самую большую сумму среди всех пар соседних элементов',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Максимальная сумма',
    examples: [
      {
        input: 'A([2, 3])',
        output: '5'
      },
      {
        input: 'A([2, 1, 3, 0])',
        output: '4'
      },
      {
        input: 'A([2, 7, 0, 2, 9])',
        output: '11'
      },
      {
        input: 'A([-2, -7, 0, -2, -9])',
        output: '-2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Ряд Фибоначчи',
    text: 'Дано целое число \\(n\\) (\\(n \\ge 2\\)). Создайте и выведите массив длины \\(n\\), в котором первые два числа единицы, а каждое следующее - это сумма двух предыдущих',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся массив',
    examples: [
      {
        input: '2',
        output: '[1, 1]'
      },
      {
        input: '5',
        output: '[1, 1, 2, 3, 5]'
      },
      {
        input: '7',
        output: '[1, 1, 2, 3, 5, 8, 13]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен соседей',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы каждый второй элемент обменялся местами с предыдущим. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2, 3, 4])',
        output: '[2, 1, 4, 3]'
      },
      {
        input: 'A([1, 2, 2, 1, 3])',
        output: '[2, 1, 1, 2, 3]'
      },
      {
        input: 'A([1, 5, 7])',
        output: '[5, 1, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен соседей без мутации',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый целочисленный массив той же длины. Заполните его числами из \\(a\\), но каждый второй элемент должен обменяться местами с левым соседом. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2])',
        output: '[2, 1]'
      },
      {
        input: 'A([1, 2, 3])',
        output: '[2, 1, 3]'
      },
      {
        input: 'A([10, 5, 1, 0])',
        output: '[5, 10, 0, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Массив накопительных сумм',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый целочисленный массив той же длины. Первый элемент взять из \\(a\\). На каждое следующее место запишите сумму предыдущего элемента и соответствующего элемента из \\(a\\). Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 1, 1])',
        output: '[1, 2, 3]'
      },
      {
        input: 'A([2, 2, 2])',
        output: '[2, 4, 6]'
      },
      {
        input: 'A([10, 5, 1, 0])',
        output: '[10, 15, 16, 16]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы элементы в нём встали в противоположном порядке (развернуть массив задом наперёд). Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[1]'
      },
      {
        input: 'A([10, 20, 15])',
        output: '[15, 20, 10]'
      },
      {
        input: 'A([5, 3, 7, 4])',
        output: '[4, 7, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот через reverse',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы элементы в нём встали в противоположном порядке (развернуть массив задом наперёд), используя метод reverse. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[10, 20, 15]',
        output: '[15, 20, 10]'
      },
      {
        input: '[5, 3, 7, 4]',
        output: '[4, 7, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот без мутации',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый целочисленный массив той же длины. Запишите в него элементы \\(a\\) в обратном порядке. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[1]'
      },
      {
        input: 'A([1, 0, 2])',
        output: '[2, 0, 1]'
      },
      {
        input: 'A([9, 7, 4])',
        output: '[4, 7, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Перестановка половин',
    text: 'Дан целочисленный массив \\(a\\) чётной длины. Измените массив таким образом, чтобы левая и правая половины поменялись местами. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2])',
        output: '[2, 1]'
      },
      {
        input: 'A([1, 2, 3, 4])',
        output: '[3, 4, 1, 2]'
      },
      {
        input: 'A([1, 2, 3, 3, 2, 1])',
        output: '[3, 2, 1, 1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимальная сумма из префиксов',
    text: 'Дан целочисленный массив \\(a\\), содержащий минимум 1 элемент. Выведите максимальную сумму из любого набора соседних элементов от первого до любого другого',
    note: 'Например, есть массив [2, 3, -1, 0]. Из него возможно взять 4 набора соседних элементов, начиная от первого: [2], [2, 3], [2, 3, -1] и [2, 3, -1, 0]. Максимальная сумма у второго набора. Её и надо вывести',
    input: '\\(a\\) - массив из целых чисел',
    output: 'Максимальная сумма',
    examples: [
      {
        input: 'A([2, 3])',
        output: '5'
      },
      {
        input: 'A([-1, -2, -3])',
        output: '-1'
      },
      {
        input: 'A([2, 3, -1, 0])',
        output: '5'
      },
      {
        input: 'A([2, 3, -1, 0, 2])',
        output: '6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Подсчёт с помощью массива',
    text: 'Дан целочисленный массив \\(a\\), состоящий из чисел в диапазоне от 0 до 5. Выведите массив из 6 чисел, в котором по индексу 0 лежит количество нулей в \\(a\\), по индексу 1 - количество единиц и т. д.',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([])',
        output: '[0, 0, 0, 0, 0, 0]'
      },
      {
        input: 'A([0, 0, 0, 2, 3, 3])',
        output: '[3, 0, 1, 2, 0, 0]'
      },
      {
        input: 'A([5, 2, 4, 3, 1, 0])',
        output: '[1, 1, 1, 1, 1, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сдвиг вправо',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы каждый элемент сдвинулся вправо на одну позицию, а последний элемент стал первым. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2, 3])',
        output: '[3, 1, 2]'
      },
      {
        input: 'A([4, 1, 2, 3])',
        output: '[3, 4, 1, 2]'
      },
      {
        input: 'A([3, 4, 1, 2])',
        output: '[2, 3, 4, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сдвиг влево',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы каждый элемент сдвинулся влево на одну позицию, а первый элемент стал последним. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2, 3])',
        output: '[2, 3, 1]'
      },
      {
        input: 'A([4, 1, 2, 3])',
        output: '[1, 2, 3, 4]'
      },
      {
        input: 'A([3, 4, 1, 2])',
        output: '[4, 1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обнуление левых',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, обнулив каждый элемент, который больше своего правого соседа. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2])',
        output: '[1, 2]'
      },
      {
        input: 'A([3, 2, 1])',
        output: '[0, 0, 1]'
      },
      {
        input: 'A([1, 2, 1, 2])',
        output: '[1, 0, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обнуление правых',
    text: 'Дан целочисленный массив \\(a\\). Измените этот массив, обнулив каждый элемент, который больше своего левого соседа. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1, 2, 3, 4])',
        output: '[1, 0, 0, 0]'
      },
      {
        input: 'A([3, 2, 1, 0])',
        output: '[3, 2, 1, 0]'
      },
      {
        input: 'A([1, 3, 2, 4])',
        output: '[1, 0, 2, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез через одного',
    text: 'Дан целочисленный массив \\(a\\). Создайте новый массив, запишите в него только каждый второй элемент \\(a\\). Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1])',
        output: '[]'
      },
      {
        input: 'A([1, 3, 2])',
        output: '[3]'
      },
      {
        input: 'A([1, 2, 1, 3])',
        output: '[2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез от m до n',
    text: 'Дан целочисленный массив \\(a\\), а также целые числа \\(m\\) и \\(n\\) (\\(1 \\le m \\le n \\le |a|\\)). Создайте новый целочисленный массив, заполненный числами из \\(a\\), начиная с позиции \\(m\\), заканчивая позицией \\(n\\) (обе включительно). Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(m\\) и \\(n\\) - целые положительные',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([5, 4, 3]), 2, 3',
        output: '[4, 3]'
      },
      {
        input: 'A([5, 6, 7, 8]), 1, 3',
        output: '[5, 6, 7]'
      },
      {
        input: 'A([9, 8, 7, 1, 2, 3]), 3, 5',
        output: '[7, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез от m до n через slice',
    text: 'Дан целочисленный массив \\(a\\), а также целые числа \\(m\\) и \\(n\\) (\\(1 \\le m \\le n \\le |a|\\)). Создайте новый целочисленный массив, заполненный числами из \\(a\\), начиная с позиции \\(m\\), заканчивая позицией \\(n\\) (обе включительно), используя метод slice. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(m\\) и \\(n\\) - целые положительные',
    output: 'Получившийся массив',
    examples: [
      {
        input: '[5, 4, 3], 2, 3',
        output: '[4, 3]'
      },
      {
        input: '[5, 6, 7, 8], 1, 3',
        output: '[5, 6, 7]'
      },
      {
        input: '[9, 8, 7, 1, 2, 3], 3, 5',
        output: '[7, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Повторение массива',
    text: 'Дан целочисленный массив \\(a\\), а также целое число \\(n\\). Создайте новый целочисленный массив с длиной в \\(n\\) раз больше \\(a\\), заполненный числами из \\(a\\), повторяя последовательность \\(n\\) раз. Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(n\\) - целое неотрицательное',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1]), 3',
        output: '[1, 1, 1]'
      },
      {
        input: 'A([1, 2]), 2',
        output: '[1, 2, 1, 2]'
      },
      {
        input: 'A([9, 8]), 3',
        output: '[9, 8, 9, 8, 9, 8]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Копирование с добавлением в конец',
    text: 'Дан целочисленный массив \\(a\\), а также целое число \\(x\\). Создайте новый целочисленный массив с длиной на 1 больше, чем \\(a\\). Заполните массив всеми элементами \\(a\\), а на последнее место запишите \\(x\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(x\\) - целое',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1]), 3',
        output: '[1, 3]'
      },
      {
        input: 'A([1, 2]), 2',
        output: '[1, 2, 2]'
      },
      {
        input: 'A([9, 8, 7, 6]), 5',
        output: '[9, 8, 7, 6, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Копирование с добавлением в начало',
    text: 'Дан целочисленный массив \\(a\\), а также целое число \\(x\\). Создайте новый целочисленный массив с длиной на 1 больше, чем \\(a\\). Заполните массив всеми элементами \\(a\\), а на первое место запишите \\(x\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(x\\) - целое',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1]), 3',
        output: '[3, 1]'
      },
      {
        input: 'A([1, 2]), 2',
        output: '[2, 1, 2]'
      },
      {
        input: 'A([9, 8, 7, 6]), 5',
        output: '[5, 9, 8, 7, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Копирование с удалением',
    text: 'Дан целочисленный массив \\(a\\), а также целое число \\(n\\). Создайте новый целочисленный массив с длиной на 1 меньше, чем \\(a\\). Заполните массив всеми элементами \\(a\\), кроме элемента по индексу \\(n\\) (\\(0 \\le n < a.length\\))',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(n\\) - целое неотрицательное',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1]), 0',
        output: '[]'
      },
      {
        input: 'A([1, 2, 3]), 2',
        output: '[1, 2]'
      },
      {
        input: 'A([9, 8, 7, 6]), 1',
        output: '[9, 7, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Конкатенация массивов',
    text: 'Даны целочисленные массивы \\(a\\) и \\(b\\). Создайте новый целочисленный массив, заполненный всеми числами из \\(a\\) и \\(b\\) (сначала все элементы из \\(a\\), затем все из \\(b\\)). Выведите новый массив',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1]), A([2])',
        output: '[1, 2]'
      },
      {
        input: 'A([1, 2]), A([3])',
        output: '[1, 2, 3]'
      },
      {
        input: 'A([9, 8]), A([9, 8, 7])',
        output: '[9, 8, 9, 8, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Конкатенация массивов через concat',
    text: 'Даны целочисленные массивы \\(a\\) и \\(b\\). Создайте новый целочисленный массив, заполненный всеми числами из \\(a\\) и \\(b\\) (сначала все элементы из \\(a\\), затем все из \\(b\\)), используя метод concat. Выведите новый массив',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: '[1], [2]',
        output: '[1, 2]'
      },
      {
        input: '[1, 2], [3]',
        output: '[1, 2, 3]'
      },
      {
        input: '[9, 8], [9, 8, 7]',
        output: '[9, 8, 9, 8, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Упорядочить левый',
    text: 'Дан целочисленный массив \\(a\\). Все элементы, кроме первого, упорядочены по возрастанию. Измените массив таким образом, чтобы он стал полностью упорядоченным. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([3, 1, 2, 4])',
        output: '[1, 2, 3, 4]'
      },
      {
        input: 'A([2, 1, 1, 2, 2])',
        output: '[1, 1, 2, 2, 2]'
      },
      {
        input: 'A([6, 2, 3, 4, 5])',
        output: '[2, 3, 4, 5, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Упорядочить правый',
    text: 'Дан целочисленный массив \\(a\\). Все элементы, кроме последнего, упорядочены по убыванию. Измените массив таким образом, чтобы он стал полностью упорядоченным. Выведите массив \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([5, 4, 2, 3])',
        output: '[5, 4, 3, 2]'
      },
      {
        input: 'A([2, 2, 1, 1, 2])',
        output: '[2, 2, 2, 1, 1]'
      },
      {
        input: 'A([5, 4, 3, 2, 6])',
        output: '[6, 5, 4, 3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обрезать по краям',
    text: 'Дан целочисленный массив \\(a\\). Скопируйте массив, удалив все нули в начале и в конце',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([0, 0, 1, 2, 0])',
        output: '[1, 2]'
      },
      {
        input: 'A([2, 0, 0, 3])',
        output: '[2, 0, 0, 3]'
      },
      {
        input: 'A([0, 0, 0, 0])',
        output: '[]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вставка массива с удалением',
    text: 'Даны целочисленные массивы \\(a\\) и \\(b\\), а также целые числа \\(n\\) и \\(m\\). Скопируйте массив, вставив вместо элементов с индексами от \\(m\\) включительно до \\(n\\) невключительно элементы массива \\(b\\)',
    note: null,
    input: '\\(a\\), \\(b\\) - массивы из целых чисел, \\(n\\) и \\(m\\) - целые неотрицательные числа',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([4, 5, 6, 7, 8]), A([9, 10]), 1, 4',
        output: '[4, 9, 10, 8]'
      },
      {
        input: 'A([2, 3]), A([]), 1, 2',
        output: '[2]'
      },
      {
        input: 'A([1, 2, 3, 4]), A([5]), 1, 1',
        output: '[1, 5, 2, 3, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вставка массива с удалением через splice',
    text: 'Даны целочисленные массивы \\(a\\) и \\(b\\), а также целые числа \\(n\\) и \\(m\\). Измените массив, вставив вместо элементов с индексами от \\(m\\) включительно до \\(n\\) невключительно элементы массива \\(b\\), используя метод splice',
    note: null,
    input: '\\(a\\), \\(b\\) - массивы из целых чисел, \\(n\\) и \\(m\\) - целые неотрицательные числа',
    output: 'Получившийся массив',
    examples: [
      {
        input: '[4, 5, 6, 7, 8], [9, 10], 1, 4',
        output: '[4, 9, 10, 8]'
      },
      {
        input: '[2, 3], [], 1, 2',
        output: '[2]'
      },
      {
        input: '[1, 2, 3, 4], [5], 1, 1',
        output: '[1, 5, 2, 3, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез от m с шагом n',
    text: 'Дан целочисленный массив \\(a\\), а также целые числа \\(m\\) и \\(n\\) (\\(1 \\le m \\le n \\le |a|\\)). Создайте новый целочисленный массив, заполненный числами из \\(a\\), начиная с позиции \\(m\\), с шагом \\(n\\). Выведите новый массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(m\\) и \\(n\\) - целые положительные',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([5, 4, 3]), 1, 1',
        output: '[5, 4, 3]'
      },
      {
        input: 'A([5, 6, 7, 8]), 1, 2',
        output: '[5, 7]'
      },
      {
        input: 'A([9, 8, 7, 1, 2, 3]), 2, 3',
        output: '[8, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Слияние массивов',
    text: 'Даны упорядоченные по неубыванию целочисленные массивы \\(a\\) и \\(b\\). Создайте новый массив, в котором будут все элементы из \\(a\\) и \\(b\\) так же в упорядоченном по неубыванию виде',
    note: null,
    input: '\\(a\\), \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([4, 7, 9]), A([3, 5])',
        output: '[3, 4, 5, 7, 9]'
      },
      {
        input: 'A([2, 3]), A([1, 2, 4])',
        output: '[1, 2, 2, 3, 4]'
      },
      {
        input: 'A([1, 3, 5]), A([2, 4, 6])',
        output: '[1, 2, 3, 4, 5, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Простая деструктуризация',
    text: 'Дан целочисленный массив \\(a\\), состоящий из двух элементов. Запишите элементы массива в две новые переменные \\(x\\) и \\(y\\), используя синтаксис присваивания с деструктуризацией (списочное присваивание). Выведите произведение \\(x\\) и \\(y\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Произведение элементов',
    examples: [
      {
        input: 'A([5, 3])',
        output: '15'
      },
      {
        input: 'A([2, 6])',
        output: '12'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация со значенями по умолчанию',
    text: 'Дан целочисленный массив \\(a\\) произвольной длины. Запишите первые два элемента массива в две новые переменные \\(x\\) и \\(y\\), используя синтаксис присваивания с деструктуризацией (списочное присваивание). Если первое число не указано, взять значение 1, если второе число не указано, взять значение 2. Выведите произведение \\(x\\) и \\(y\\)',
    note: 'Если написать const [a, b] = [1], то у b будет значение undefined. Можно использовать синтаксис значения по умолчанию: const [a, b=2] = [1], тогда у b будет значение 2, если в массиве недостаточно элементов. Можно делать и так: const [a=1, b] = [undefined, 2], тогда присвоятся значения 1 и 2 соответственно',
    input: '\\(a\\) - массив из целых чисел',
    output: 'Оба значения',
    examples: [
      {
        input: 'A([10, 20])',
        output: '10 20'
      },
      {
        input: 'A([20])',
        output: '20 2'
      },
      {
        input: 'A([undefined, 30])',
        output: '1 30'
      },
      {
        input: 'A([, 30])',
        output: '1 30'
      },
      {
        input: 'A([])',
        output: '1 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация со вложенностью',
    text: 'Дан массив \\(a\\), состоящий из двух целочисленных массивов, по два элемента в каждом. Запишите числа из массива в 4 новые переменные \\(x1\\), \\(y1\\), \\(x2\\), \\(y2\\), используя синтаксис присваивания с деструктуризацией. Выведите эти переменные',
    note: null,
    input: '\\(a\\) - целочисленная матрица 2x2',
    output: 'Все элементы',
    examples: [
      {
        input: 'A([A([5, 3]), A([2, 4])])',
        output: '5 3 2 4'
      },
      {
        input: 'A([A([1, 4]), A([5, 7])])',
        output: '1 4 5 7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация c созданием списка на ходу',
    text: 'Дан массив \\(a\\), состоящий из целочисленных массивов, по два элемента в каждом. Запишите числа из первого массива в \\(a\\) в \\(x1\\) и \\(y1\\), а числа из последнего массива в \\(a\\) в \\(x2\\) и \\(y2\\), используя синтаксис присваивания с деструктуризацией. Выведите эти переменные',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: '\\(x1\\), \\(y1\\), \\(x2\\), \\(y2\\) через пробел',
    examples: [
      {
        input: 'A([A([5, 3])])',
        output: '5 3 5 3'
      },
      {
        input: 'A([A([5, 3]), A([2, 4])])',
        output: '5 3 2 4'
      },
      {
        input: 'A([A([1, 4]), A([1, -1]), A([5, 7])])',
        output: '1 4 5 7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация на элемент и хвост',
    text: 'Дан целочисленный массив \\(a\\), содержащий хотя бы один элемент. С помощью деструктуризации разложите его на первый элемент \\(x\\) и список из остальных элементов \\(y\\). Выведите \\(x\\) и \\(y\\)',
    note: 'Список из остальных элементов получить с помощью оператора rest',
    input: '\\(a\\) - массив из целых чисел',
    output: '\\(x\\), \\(y\\) через пробел',
    examples: [
      {
        input: 'A([1])',
        output: '1 []'
      },
      {
        input: 'A([2, 5, 6])',
        output: '2 [5, 6]'
      },
      {
        input: 'A([4, 5, 8, 9])',
        output: '4 [5, 8, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация на два элемента и хвост',
    text: 'Дан целочисленный массив \\(a\\), содержащий хотя бы два элемента. С помощью деструктуризации разложите его на первый элемент \\(x\\), второй элемент \\(y\\) и список из остальных элементов \\(z\\) (с помощью rest). Выведите \\(x\\), \\(y\\) и \\(z\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: '\\(x\\), \\(y\\), \\(z\\) через пробел',
    examples: [
      {
        input: 'A([1, 2])',
        output: '1 2 []'
      },
      {
        input: 'A([2, 5, 6])',
        output: '2 5 [6]'
      },
      {
        input: 'A([4, 5, 8, 9])',
        output: '4 5 [8, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сложная деструктуризация',
    text: 'Дан массив \\(a\\), состоящий из целочисленных массивов (длиной не меньше 1), содержащий хотя бы один элемент. С помощью деструктуризации создайте 4 переменных: \\(x1\\), \\(y1\\), \\(x2\\), \\(y2\\). В \\(x1\\) нужно положить первый элемент первого массива в \\(a\\), в \\(y1\\) все остальные первого массива в \\(a\\). В \\(x2\\) и \\(y2\\) первый элемент и остальные элементы последнего массива \\(a\\) соответственно',
    note: 'Создать все переменные в одну строчку',
    input: '\\(a\\) - массив из целых чисел',
    output: '\\(x1\\), \\(y1\\), \\(x2\\), \\(y2\\) через пробел',
    examples: [
      {
        input: 'A([A([1])])',
        output: '1 [] 1 []'
      },
      {
        input: 'A([A([2, 5, 6]), A([7, 3, 9])])',
        output: '2 [5, 6] 7 [3, 9]'
      },
      {
        input: 'A([A([4]), A([]), A([8]), A([9, 2]))',
        output: '4 [] 9 [2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация в for-of',
    text: 'Дан массив \\(a\\), состоящий из пар чисел (каждая пара - массив длины 2). Выведите сумму каждой пары, используя деструктуризацию в цикле for-of',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'A([A([2, 5])])',
        output: '7'
      },
      {
        input: 'A([A([1, 2]), A([3, 4])])',
        output: '3\n7'
      },
      {
        input: 'A([A([5, 1]), A([7, 8]), A([5, 5])])',
        output: '6\n15\n10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Минимальная площадь',
    text: 'Дан массив \\(a\\), состоящий из пар чисел (хотя бы одной). Каждая пара - массив длины 2. Числа в паре - стороны прямоугольника. Выведите минимальную площадь прямоугольника',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Минимальная площадь',
    examples: [
      {
        input: 'A([A([5, 4])])',
        output: '20'
      },
      {
        input: 'A([A([3, 3]), A([2, 2]), A([4, 5])])',
        output: '4'
      },
      {
        input: 'A([A([1, 3]), A([3, 1]), A([2, 1])])',
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимальный периметр',
    text: 'Дан массив \\(a\\), состоящий из пар чисел (хотя бы одной). Каждая пара - массив длины 2. Числа в паре - стороны прямоугольника. Выведите максимальный периметр прямоугольника',
    note: null,
    input: 'Задана последовательность \\(a\\). (\\(1 \\le |a| \\le 50, 1 \\le a_i \\le 100\\))',
    output: 'Максимальный периметр',
    examples: [
      {
        input: 'A([A([5, 3])])',
        output: '16'
      },
      {
        input: 'A([A([1, 3]), A([3, 1]), A([2, 1])])',
        output: '8'
      },
      {
        input: 'A([A([5, 5]), A([3, 4]), A([4, 4])])',
        output: '20'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация в параметрах функции',
    text: 'Даны два целочисленных массива. Сделайте деструктуризацию в параметрах функции, чтобы в \\(a\\) и \\(b\\) попали первые два значения из первого массива, а в \\(c\\) и \\(d\\) первое и остальные значения из второго массива. Выведите полученные переменные',
    note: null,
    input: 'Два целочисленных массива',
    output: '\\(a\\), \\(b\\), \\(c\\), \\(d\\) через пробел',
    examples: [
      {
        input: 'A([1, 2]), A([3, 4, 5])',
        output: '1 2 3 [4, 5]'
      },
      {
        input: 'A([1, 2, 3]), A([4])',
        output: '1 2 4 []'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для добавления в конец без мутации',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). Создайте и выведите новый массив с помощью оператора spread, содержащий все элементы \\(a\\), а также число \\(x\\) в конце',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(x\\) - целое число',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([]), 7',
        output: '[7]'
      },
      {
        input: 'A([1, 2]), 3',
        output: '[1, 2, 3]'
      },
      {
        input: 'A([4, 5, 6]), 10',
        output: '[4, 5, 6, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для добавления в начало без мутации',
    text: 'Дан целочисленный массив \\(a\\) и число \\(x\\). Создайте и выведите новый массив с помощью оператора spread, содержащий в начале элемент \\(x\\), а затем все элементы \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(x\\) - целое число',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([]), 7',
        output: '[7]'
      },
      {
        input: 'A([1, 2]), 3',
        output: '[3, 1, 2]'
      },
      {
        input: 'A([4, 5, 6]), 10',
        output: '[10, 4, 5, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для конкатенации без мутации',
    text: 'Даны целочисленные массивы \\(a\\) и \\(b\\). Создайте новый целочисленный массив с помощью оператора spread, заполненный всеми числами из \\(a\\) и \\(b\\) (сначала все элементы из \\(a\\), затем все из \\(b\\)). Выведите новый массив',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из целых чисел',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([1]), A([2])',
        output: '[1, 2]'
      },
      {
        input: 'A([1, 2]), A([3])',
        output: '[1, 2, 3]'
      },
      {
        input: 'A([9, 8]), A([9, 8, 7])',
        output: '[9, 8, 9, 8, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для произвольного заполнения',
    text: 'Даны целочисленные массивы \\(a\\), \\(b\\) и число \\(x\\). Создайте и выведите новый массив с помощью оператора spread, содержащий в начале все элементы \\(a\\), затем число \\(x\\), а затем все элементы \\(b\\)',
    note: null,
    input: '\\(a\\), \\(b\\) - массивы из целых чисел, \\(x\\) - целое число',
    output: 'Получившийся массив',
    examples: [
      {
        input: 'A([]), A([]), 7',
        output: '[7]'
      },
      {
        input: 'A([1]), A([5]), 3',
        output: '[1, 3, 5]'
      },
      {
        input: 'A([1, 2]), A([2, 1]), 3',
        output: '[1, 2, 3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы: push',
    text: 'Дан целочисленный массив \\(a\\) и целое число \\(n\\). Измените массив таким образом, чтобы число \\(n\\) добавилось в конец массива, увеличив его длину, используя метод push. Выведите массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(n\\) - целое число',
    output: 'Получившийся массив',
    examples: [
      {
        input: '[], 7',
        output: '[7]'
      },
      {
        input: '[1, 3], 2',
        output: '[1, 3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы: pop',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы последнее число было удалено из массива, используя метод pop. Выведите удалённое число, затем сам массив через пробел',
    note: 'Обратите внимание, что метод pop возвращает удалённый элемент',
    input: '\\(a\\) - массив из целых чисел',
    output: 'Удалённое число и получившийся массив',
    examples: [
      {
        input: '[7]',
        output: '7 []'
      },
      {
        input: '[1, 3, 2]',
        output: '2 [1, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы: shift',
    text: 'Дан целочисленный массив \\(a\\). Измените массив таким образом, чтобы первое число было удалено из массива, используя метод shift. Выведите удалённое число, затем сам массив через пробел',
    note: 'Обратите внимание, что метод shift возвращает удалённый элемент',
    input: '\\(a\\) - массив из целых чисел',
    output: 'Удалённое число и получившийся массив',
    examples: [
      {
        input: '[7]',
        output: '7 []'
      },
      {
        input: '[1, 3, 2]',
        output: '1 [3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы: unshift',
    text: 'Дан целочисленный массив \\(a\\) и целое число \\(n\\). Измените массив таким образом, чтобы число \\(n\\) добавилось в начало массива, увеличив его длину, используя метод unshift. Выведите массив',
    note: null,
    input: '\\(a\\) - массив из целых чисел, \\(n\\) - целое число',
    output: 'Получившийся массив',
    examples: [
      {
        input: '[], 7',
        output: '[7]'
      },
      {
        input: '[1, 3], 2',
        output: '[2, 1, 3]'
      }
    ]
  }
]
